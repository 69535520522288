import uniqid from 'uniqid'
import Apple from '@material-ui/icons/Apple'
import BlockIcon from '@material-ui/icons/Block'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import ShopIcon from '@material-ui/icons/Shop'

import './ProjectContainer.css'

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <h3>{project.name}</h3>

    <p className='project__description'>{project.description}</p>
    {project.stack && project.stack.length > 0 ? (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    ) : (
      <p className='project__stack'>
        <BlockIcon className='font--md mr-1' />
        Confidential
      </p>
    )}
    {project.sourceCode && (
      <a
        href={project.sourceCode}
        aria-label='source code'
        className='link link--icon'
        target='_blank'
        rel='noreferrer'
      >
        <GitHubIcon className='font--md' />
      </a>
    )}
    {project.livePreview && (
      <a
        href={project.livePreview}
        aria-label='live preview'
        className='link link--icon font--md'
        target='_blank'
        rel='noreferrer'
      >
        <LaunchIcon className='font--md' />
      </a>
    )}
    {project.playStore && (
      <a
        href={project.playStore}
        aria-label='play store'
        className='link link--icon'
        target='_blank'
        rel='noreferrer'
      >
        <ShopIcon className='font--md' />
      </a>
    )}
    {project.appStore && (
      <a
        href={project.appStore}
        aria-label='app store'
        className='link link--icon'
        target='_blank'
        rel='noreferrer'
      >
        <Apple className='font--md' />
      </a>
    )}
  </div>
)

export default ProjectContainer
