import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a href='!#' className='link footer__link'>
      © {new Date().getFullYear()} - All rights reserved
    </a>
  </footer>
)

export default Footer
