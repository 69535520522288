import { contact } from '../../portfolio'
import './Contact.css'

const Contact = () => (
  <section className='section contact center' id='contact'>
    <h2 className='section__title'>Contact</h2>
    <a href={`mailto:${contact.email}`}>
      <span type='button' className='btn btn--outline'>
        Email me
      </span>
    </a>
    {/* Divider like -------OR------ */}
    {/* 
    <div className='contact__divider'>
      <span className='contact__divider--text'>OR</span>
    </div>
    <form className='form'>
      <div className='form__group'>
        <input
          type='text'
          className='form__input'
          placeholder='Your name'
          id='fullname'
          name='fullname'
          required
        />
        <input
          type='email'
          className='form__input'
          placeholder='Your email'
          id='email'
          name='email'
          required
        />
      </div>
      <textarea
        className='form__textarea'
        placeholder='Your message'
        id='message'
        name='message'
        required
        rows={10}
      />
      <button type='submit'
        className='btn btn--outline'>
        Send message
      </button>
    </form> 
    */}
  </section>
)

export default Contact
