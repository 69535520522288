const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://dashgin.com',
  title: 'DK.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Dashgin Khudiyev',
  role: 'Software Engineer',
  description:
    'I am a software engineer! I can help you build a product, feature or website. Look through some of my work and experience! If you like what you see and have a project you need coded, don`t hestiate to contact me.',
  resume: 'https://dashgin.com/resume.pdf',
  social: {
    linkedin: 'https://linkedin.com/in/dashgin-khudiyev',
    github: 'https://github.com/dashgin',
  },
}

const projects = [
  {
    name: 'dmpservice',
    description:
      'I am part of the team that is building SAAS for supply chain management. ' +
      'Microservices architecture with CQRS and Event Sourcing. I am responsible for designing and implementing ' +
      'microservices, database design, deployment, monitoring and more as software engineer.',
    // sourceCode: 'https://github.com/dashgin',
    livePreview: 'https://dmpservice.org',
  },
  {
    name: 'itopix',
    description:
      'Backend for hiring platform for IT companies and candidates.' +
      'Companies can post jobs and candidates can apply for jobs.' +
      ' There are subscription plans for companies and candidates with recurring payments.',
    stack: ['Django', 'Python', 'PostgreSQL', 'Docker'],
    livePreview: 'https://itopix.io',
  },
  {
    name: 'SukanAz',
    description:
      'Backend for course management system. ' +
      'Available on Google Play and App Store for students and teachers. ' +
      'There is web app (connected to Google Drive API) for admins to manage content.',
    stack: [
      'Django',
      'Python',
      'PostgreSQL',
      'Docker',
      'Nginx',
      'Google Drive API',
    ],
    // sourceCode: 'https://github.com/dashgin',
    livePreview: 'https://sukanaz.com',
    appStore: 'https://apps.apple.com/az/app/sukan-az/id6449287745',
    playStore:
      'https://play.google.com/store/apps/details?id=com.sukan.studentquiz.student_quiz_app',
  },
]
const skills = [
  'Python',
  'Django',
  'Django REST Framework',
  'Celery',
  'Testing',
  'JavaScript',
  'React',
  'Web Crawling / Scraping / Playwright / Selenium',
  'PostgreSQL / Database design / Performance tuning',
  'Microservices Design/ CQRS/ Event Sourcing',
  'Apache Kafka / Redis',
  'Elasticsearch / ELK Stack / Sentry',
  'Git / GitHub actions / GitLab CI/CD',
  'Docker / Docker Compose',
  'Linux',
  'Nginx',
  'OWASP Top 10',
]

const contact = {
  email: 'xudiyevdasqin7777@mail.com',
}

export { header, about, projects, skills, contact }
